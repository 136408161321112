import {
	type SubmissionResult,
	getFormProps,
	getInputProps,
	getSelectProps,
	useForm,
	useInputControl,
} from "@conform-to/react";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import { z } from "zod";

import { useIsPending } from "#app/hooks/use-is-pending";

import { Button } from "./button";
import { Form, FormControl, FormItem, FormLabel, FormMessage } from "./form";
import { Input } from "./input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./select";
import { Switch } from "./switch";

export const categories = ["Gender", "Style", "Personality", "Occupation", "Relationship"] as const;

export const TagSchema = z.object({
	id: z.string().optional(),
	name: z.string().min(1).max(255),
	slug: z
		.string({
			required_error: "Slug is required",
		})
		.regex(/^[a-z0-9-]+$/, {
			message: "Slug can only include lowercase letters, numbers, and dashes",
		})
		.min(1, {
			message: "Slug is too short",
		})
		.max(255, {
			message: "Slug is too long",
		})
		.transform((value) => value.toLowerCase()),
	category: z.enum(categories),
	isFeatured: z.coerce.boolean(),
});

export function TagForm({
	id = "tag-form",
	lastResult,
	defaultValue,
	title,
	buttonLabel = "Create",
	actions,
}: {
	id?: string;
	lastResult?: SubmissionResult<string[]> | null | undefined;
	defaultValue?: Omit<z.infer<typeof TagSchema>, "category"> & {
		category?: string;
	};
	title?: string;
	buttonLabel?: string;
	actions?: React.ReactNode;
}) {
	const [form, fields] = useForm({
		id,
		constraint: getZodConstraint(TagSchema),
		lastResult,
		defaultValue: {
			category: "default",
			isFeatured: false,
			...defaultValue,
		},
		onValidate({ formData }) {
			return parseWithZod(formData, {
				schema: TagSchema,
			});
		},
		shouldRevalidate: "onBlur",
	});
	const isFeaturedControl = useInputControl(fields.isFeatured);
	const isPending = useIsPending();

	return (
		<div className="p-4 md:p-8">
			<div className="mx-auto max-w-screen-lg rounded-lg border border-border bg-background p-4 shadow-md dark:border-zinc-700 dark:bg-[#202035] md:p-8">
				<h1 className="mb-6 font-bold text-2xl tracking-tight">{title}</h1>

				<div className="relative">
					<div className="overflow-auto">
						<Form {...getFormProps(form)} className="flex flex-col gap-8" context={form.context} method="POST">
							<input
								{...getInputProps(fields.id, {
									type: "hidden",
								})}
							/>

							<div className="grid grid-cols-12 gap-4">
								<FormItem className="col-span-12 md:col-span-6" name={fields.name.name}>
									<FormLabel>Name</FormLabel>
									<FormControl>
										<Input
											{...getInputProps(fields.name, {
												type: "text",
											})}
											placeholder="e.g. RPG"
										/>
									</FormControl>
									<FormMessage />
								</FormItem>

								<FormItem className="col-span-12 md:col-span-6" name={fields.slug.name}>
									<FormLabel>Slug</FormLabel>
									<FormControl>
										<Input
											{...getInputProps(fields.slug, {
												type: "text",
											})}
											placeholder="e.g. rpg"
										/>
									</FormControl>
									<FormMessage />
								</FormItem>
							</div>

							<div className="grid grid-cols-12 gap-4">
								<FormItem className="col-span-12 md:col-span-6" name={fields.category.name}>
									<FormLabel>Category</FormLabel>

									<FormControl>
										<Select
											{...getSelectProps(fields.category)}
											defaultValue={fields.category.initialValue}
											onValueChange={(value) => {
												form.update({
													name: "category",
													value,
												});
											}}
											value={fields.category.value}
										>
											<SelectTrigger>
												<SelectValue placeholder="Select a category" />
											</SelectTrigger>

											<SelectContent>
												{categories.map((category) => (
													<SelectItem id={category} key={category} value={category}>
														{category}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</FormControl>
									<FormMessage />
								</FormItem>

								<FormItem className="col-span-12 flex flex-col md:col-span-6" name={fields.isFeatured.name}>
									<FormLabel>Is featured?</FormLabel>
									<div className="pt-2.5">
										<FormControl>
											<Switch
												checked={isFeaturedControl.value === "true"}
												id={fields.isFeatured.id}
												onBlur={isFeaturedControl.blur}
												onCheckedChange={(value) => {
													isFeaturedControl.change(value.toString());
												}}
												onFocus={isFeaturedControl.focus}
											/>
										</FormControl>
									</div>

									<FormMessage />
								</FormItem>
							</div>

							<div className="flex justify-end gap-2">
								{actions}
								<Button className="md:w-fit" disabled={isPending} type="submit">
									{buttonLabel}
								</Button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	);
}
